import React, { createContext, useState } from "react";
import api from "../Common/api";

const AuthContext = createContext(null);

export const AuthProvider = ({children}) => {        
    const userInSessionStorage = localStorage.getItem("auth") && JSON.parse(localStorage.getItem("auth"));    
    const [user, setUser] = useState(userInSessionStorage);

    const signin = (username, password, successCallback, failCallback) => {
        api.post("/auth/signin", {username, password})
            .then((res) => {
                const resData = res.data;
                if (resData.success) {
                    const result = resData.result;
                    const newUser = {userId: result.userId, username: result.username, token: result.token, userType: result.userType, refreshToken: result.refreshToken};
                    localStorage.setItem("auth", JSON.stringify(newUser));
                    setUser(newUser);
                    api.defaults.headers.common["Authorization"] = `Bearer ${newUser.token}`;
                    if (!!successCallback) {
                        successCallback();
                    }
                } else {
                    if (!!failCallback) {
                        failCallback(resData);
                    }
                }
            })
    }

    const refreshAuth = async () => {
        const res = await api.post("/auth/refreshToken", {refreshToken: user.refreshToken})            
        const { success, result } = res.data;
        let token = "";
        if (success) {                    
            const newUser = {userId: result.userId, username: result.username, token: result.token, userType: result.userType, refreshToken: result.refreshToken};
            localStorage.setItem("auth", JSON.stringify(newUser));
            setUser(newUser);
            api.defaults.headers.common["Authorization"] = `Bearer ${newUser.token}`;
            token = newUser.token;            
        }             

        return {success, token: token};
    }

    const clearAuth = (successCallback) => {
        localStorage.removeItem("auth");
        setUser(null);
        if (successCallback) {
            successCallback();
        }
    }

    const value = {user, signin, clearAuth, refreshAuth};

    return <AuthContext.Provider value={value}>{ children }</AuthContext.Provider>
}

export const useAuth = () => {
    return React.useContext(AuthContext);
}