import React, { Fragment, useEffect, useState } from 'react';
import {  Button, Col, Form, Modal, Row } from 'react-bootstrap';
import ReactTable from '../ReactTable/ReactTable';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import api from '../Common/api';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { JS_DATE_FORMAT, MOMENT_DISP_DATE_FORMAT, MOMENT_DISP_DATE_TIME_FORMAT } from '../Common/config';
import { Link, useNavigate, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import LoadingButton from '../Common/LoadingButton';
import FormField from '../Common/FormField';

const checkDateRange = (toDate, fromField, getValues) => {
    const fromDate = getValues(fromField);
    if (!fromDate || !toDate)
        return true;

    return toDate >= fromDate || "請提供正確日期範圍";
}

const Events = (props) => {
    
    const { t } = useTranslation();
    
    const dateSort = React.useCallback((rowA, rowB, columnId, desc) => {
        // console.log(rowA, rowB, columnId, desc);
        if (rowA.original[columnId] == rowB.original[columnId])
            return 0;

        return rowA.original[columnId] > rowB.original[columnId] ? 1 : -1
    }, []);

    const columns = React.useMemo(() => [
        {Header: t("event.title"), accessor: 'title'},
        {Header: t("event.category"), accessor: 'category', Cell: ({row}) => (
            <>
                {t("event.category_" + row.original.category)}
            </>
        )},
        {Header: t("event.effectiveFrom"), id: "effectiveFrom", accessor: (row) => {return moment(row.effectiveFrom).format(MOMENT_DISP_DATE_TIME_FORMAT)}, sortType: dateSort},
        {Header: t("event.effectiveTo"), id: "effectiveTo", accessor: (row) => {return moment(row.effectiveTo).format(MOMENT_DISP_DATE_TIME_FORMAT)}, sortType: dateSort},
        {Header: t("operation"), 
            Cell: ({ row }) => (
                <div className="">
                    <a href='' onClick={(event) => onUpdateBtnClick(event, row)} className="d-block d-sm-inline">[更新]</a>
                    <Link to={"/events/enrolls/" + row.original.eventId} className="d-block d-sm-inline ms-sm-1">[報名]</Link>
                    <Link to={"/events/attendances/" + row.original.eventId} className="d-block d-sm-inline ms-sm-1">[點到]</Link>
                    <Link to={"/events/qrcode/" + row.original.eventId} className="d-block d-sm-inline ms-sm-1">[掃描頁面]</Link>
                </div>
            ),
            disableSortBy: true, 
            width: 2000,           
        }
    ], []);

    const [data, setData] = useState([]);   
    const [loading, setLoading] = useState(true); 
    const [showNewDialog, setShowNewDialog] = useState(false);    
    
    const formProps = useForm();
    const { register, handleSubmit, setValue, getValues, reset, watch, formState: { errors } } = formProps;
    const watchEnforceEnroll = watch("enforceEnroll", false);

    const [savingEvent, setSavingEvent] = useState(false);
    const onSubmit = (data) => {   
        setSavingEvent(true);     
        var convertedData = {...data};
        convertedData.effectiveFrom = moment(convertedData.effectiveFrom).format();
        convertedData.effectiveTo = moment(convertedData.effectiveTo).format();

        const thenFunc = (res) => {
            const { success, result } = res.data;
            if (success) {
                toast.success(t("success"));
                setShowNewDialog(false);
                reset();
                getData();                
            }
        }

        const finallyFunc = () => {
            setSavingEvent(false);
        }

        if (!!convertedData.eventId) {
            api.put("/events/" + convertedData.eventId, convertedData).then(thenFunc).finally(finallyFunc);
        } else {
            api.post("/events", convertedData).then(thenFunc).finally(finallyFunc);
        }
    }

    const onUpdateBtnClick = (event, row) => {
        event.preventDefault();
        const original = {...row.original};  
        original.effectiveFrom = moment(original.effectiveFrom).toDate();
        original.effectiveTo = moment(original.effectiveTo).toDate();
        reset(original);
        setShowNewDialog(true);
    }

    

    const getData = () => {
        setLoading(true);
        api.get("/events")
            .then((res) => {
                if (!!res.data && res.data.success) {
                    setData([...res.data.result]);                  
                    setLoading(false);
                }
            })
            .catch((error) => {
                // console.log(error.toJSON());
                // navigate("/login");
            })
    };    

    useEffect(() => {
        getData();
    }, []);    

    useEffect(() => {
        reset();
    }, [showNewDialog])

    return (
        <Fragment>
            <Row>
                <Col>
                    <h2 class="page-title">
                        活動管理
                    </h2>
                </Col>
            </Row>

            <Row>
                <Col>
                    <Button variant='primary' onClick={() => { setShowNewDialog(true) }}>新增</Button>
                    {/* <Button variant='primary' className='ms-2'>批量新增</Button> */}
                </Col>
            </Row>

            <Row>
                <Col>
                    <ReactTable {...{columns, data, loading, id: "eventTable"}}></ReactTable>
                </Col>
            </Row>

            <Modal show={showNewDialog} onHide={() => setShowNewDialog(false)} size="xl" backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>新增活動</Modal.Title>
                </Modal.Header>
                <Form onSubmit={handleSubmit(onSubmit)}>
                <Modal.Body>
                    
                        <Form.Group className="mb-3" controlId="title">
                            <Form.Label>{t("event.title")}</Form.Label>
                            <Form.Control type="text" placeholder="" name="title" {...register("title", {required: "This field is required"})} isInvalid={!!errors.title} />                               
                            <Form.Control.Feedback type="invalid">
                                {errors.title && <p>{ errors.title.message }</p>}
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="description">
                            <Form.Label>{t("event.description")}</Form.Label>
                            <Form.Control as="textarea" rows={3} placeholder="" name="description" {...register("description", {})} isInvalid={!!errors.description} />                               
                            <Form.Control.Feedback type="invalid">
                                {errors.description && <p>{ errors.description.message }</p>}
                            </Form.Control.Feedback>
                        </Form.Group>                        

                        <Form.Group className="mb-3" controlId="effectiveFrom">                            
                            <FormField controlId="effectiveFrom" label={t("event.effectiveFrom")} type="datepicker"  
                                rules={{ required: true }} {...formProps} 
                                {...{datePickerConfig: {showTimeSelect: true, timeFormat: "HH:mm", dateFormat: "dd MMM yyyy HH:mm"}}} 
                            />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="effectiveTo">
                            <FormField controlId="effectiveTo" label={t("event.effectiveTo")} type="datepicker"  
                                rules={{ required: true, validate: (value) => checkDateRange(value, "effectiveFrom", getValues) }} {...formProps} 
                                {...{datePickerConfig: {showTimeSelect: true, timeFormat: "HH:mm", dateFormat: "dd MMM yyyy HH:mm", minDate: getValues("effectiveFrom")}}} />
                        </Form.Group>

                        <Form.Check
                            type="switch"
                            id="ableWalkin"
                            label={t("event.ableWalkin")}
                            className="mt-4"
                            {...register("ableWalkin")}
                        />

                        <Form.Check
                            type="switch"
                            id="enforceEnroll"
                            label={t("event.enforceEnroll")}
                            className="mt-4"
                            {...register("enforceEnroll")}
                        />                        

                        <Form.Group className="mb-3" controlId="enforceMsg">
                            <Form.Label>必須報名的顯示訊息</Form.Label>
                            <Form.Control as="textarea" rows={3} placeholder="" name="enforceMsg" {...register("enforceMsg", {})} isInvalid={!!errors.enforceMsg} disabled={!watchEnforceEnroll} />
                            <Form.Control.Feedback type="invalid">
                                {errors.enforceMsg && <p>{ errors.enforceMsg.message }</p>}
                            </Form.Control.Feedback>
                        </Form.Group>                        

                        <Form.Control type="hidden" {...register("eventId")} />

                </Modal.Body>
                <Modal.Footer>
                    <Button variant='secondary' type='button' onClick={() => {reset(); setShowNewDialog(false);}}>取消</Button>                    
                    <LoadingButton variant='primary' type='submit' loading={savingEvent}>儲存</LoadingButton>
                </Modal.Footer>
                </Form>


            </Modal>

        </Fragment>
    )

}

export default Events;