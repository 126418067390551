import { getConfig } from "@testing-library/react";
import axios, { CancelToken } from "axios";

const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
});

export const setupInterceptors = (navigate, auth) => {
    api.interceptors.response.use((res) => res, async (error) => { 
        const originalRequest = error.config;       
        const { status } = error.response || {};
        if (status == "401") {            
            const {success: refreshSuccess, token} = await auth.refreshAuth();
            if (refreshSuccess) {                
                originalRequest.headers["Authorization"] = `Bearer ${token}`;                
                return api(originalRequest);
            } else {
                navigate("/login?message=relogin", {replace: true});
            }                        
        }
        Promise.reject(error)
    })

    api.interceptors.request.use((config) => {                
        if (!!auth && !!auth.user && isTokenExpired(auth.user.token)) {
            // auth.clearAuth();
            // navigate("/login?message=relogin", {replace: true});            
            /** Try to use refresh token get the auth again */            
            // auth.refreshAuth(() => {
            //     return {
            //         ...config,
            //         cancelToken: new CancelToken((cancel) => cancel('Cancel request'))
            //     };
            // }, (resData) => {
            //     auth.clearAuth();
            //     navigate("/login?message=relogin", {replace: true});

            //     return {
            //         ...config,
            //         cancelToken: new CancelToken((cancel) => cancel('Cancel request'))
            //     };
            // });
        }

        return config;
        

        
    })

    const isTokenExpired = token => token && Date.now() >= (JSON.parse(atob(token.split('.')[1]))).exp * 1000;
}



export default api;
