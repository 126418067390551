import React, { Fragment, useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import SideNav from './SideNav2';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useLocation } from 'react-router';

import '../Tabler/css/tabler.min.css';
import '../Tabler/js/tabler';

const BaseLayout = (props) => {	

	

	const [showSidebar, setShowSidebar] = useState(false);	

	useEffect(() => {
		if (showSidebar) {
			document.body.classList.add("sb-sidenav-toggled");
		} else {
			document.body.classList.remove("sb-sidenav-toggled");
		}
	}, [showSidebar]);
	
	const location = useLocation();

	useEffect(() => {
		setShowSidebar(false);
		window.scrollTo({
			top: 0,
			behavior: "smooth"
		});
	}, [location]);

    return (
		<Fragment>
			{/* <div className="sb-nav-fixed"> */}
				{/* <nav className="sb-topnav navbar navbar-expand navbar-dark bg-dark">
					<Link className="navbar-brand ps-3" to="/">SFO</Link>
					<button className="btn btn-link btn-sm order-1 order-lg-0 me-4 me-lg-0" id="sidebarToggle" href="#!" onClick={() => setShowSidebar(!showSidebar)}><FontAwesomeIcon icon={faBars} /></button>
				</nav> */}
				<div className="wrapper"> 
                    <SideNav />
					<div className="page-wrapper">
						<main>
                            <div className="mt-4 mx-4">
                                <Outlet />
								{props.children}
                            </div>
						</main>

						<div style={{"minHeight": "256px"}}></div>
					</div>
					<ToastContainer />
				</div>
			{/* </div> */}
		</Fragment>        
    )
}


export default BaseLayout;