import React, { useEffect, useState } from 'react';
import { Col, Container, Row, Image, Card } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
// import './styles.css';
import logo from './sfo_logo.jpeg';
import { useTranslation } from 'react-i18next';
import api from './Common/api';
import moment from 'moment';
import { MOMENT_DISP_DATE_FORMAT, MOMENT_DISP_DATE_TIME_FORMAT } from './Common/config';
import { NavLink } from 'react-router-dom';
import { useAuth } from './AuthContext/AuthContext';

function App() {	

	const { t } = useTranslation();
	const auth = useAuth();

	const [ recentEvents, setRecentEvents ] = useState([]);
	const [ todayWorkingEvent, setTodayWorkingEvent ] = useState({});

	useEffect(() => {
		const day = 7;
		const from = moment().startOf("day");
		const to = moment().add(day, "days").endOf("day");		

		api.get("/events/effectiveFromBetween", {params: {from: from.toISOString(), to: to.toISOString()}})
			.then(res => {
				const {success, result} = res.data;
				if (success) {
					setRecentEvents([...result]);
				}
			});

		api.get("/events/todayWorkingEvent", {params: {}})
			.then(res => {
				const {success, result} = res.data;
				if (success) {
					setTodayWorkingEvent({...result});
				}
			})
	}, []);

	return (
		<Container fluid>			
			<Row className="justify-content-md-center">
				<Col xs lg="5">
					<div className="text-center">
						<Image src={logo} fluid />
						<h1>SFO Attendance System</h1>						
					</div>
				</Col>				
			</Row>

			<Row className="row-deck row-cards">
				<Col md={12} lg={8}>
					<Card>
						<Card.Header>
							<Card.Title>七日內的活動</Card.Title>							
						</Card.Header>
						<div className="card-table table-responsive">
							<table className="table table-vcenter">
								<thead>
									<tr><th>{t("event.title")}</th><th>{t("event.effectiveFrom")}</th></tr>
								</thead>
								<tbody>
									{recentEvents.length > 0 && recentEvents.map(event => (
										<tr key={event.eventId}>
											<td><NavLink to={"/events/qrcode/" + event.eventId}>{event.title}</NavLink></td>
											<td>{moment(event.effectiveFrom).format(MOMENT_DISP_DATE_TIME_FORMAT)}</td>
										</tr>
									))}
									{recentEvents.length <= 0 && (<tr><td colSpan={9999}>沒有活動</td></tr>) }
								</tbody>
							</table>
						</div>
					</Card>
				</Col>
				<Col md={12} lg={4}>
					<Card>
						<Card.Header>
							<Card.Title>今天上班日</Card.Title>							
						</Card.Header>
						<Card.Body>							
							{moment(todayWorkingEvent.effectiveFrom).format(MOMENT_DISP_DATE_FORMAT)}
							<br/>
								<NavLink to={{pathname: "/events/qrcode/" + todayWorkingEvent.eventId, search: "?kioskMode=true"}} >[掃描頁面]</NavLink>
						</Card.Body>

					</Card>
				</Col>				
			</Row>
		</Container>		
	)
}

export default App
