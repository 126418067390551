import React, { useState } from "react";
import { Navigate, useLocation, useNavigate } from "react-router";
import { useAuth } from "../AuthContext/AuthContext";
import api from "./api";

const RequireAuth = ({children, requiredUserType}) => {

    const auth = useAuth();
    const location = useLocation();
    const navigate = useNavigate();
    const [sendToLogin, setSendToLogin] = useState(false);
    
    if (!auth || !auth.user) {                
        api.defaults.headers.common["Authorization"] = "";
        return <Navigate to="/login" state={{from: location}} replace />;
    } else {
        api.defaults.headers.common["Authorization"] = `Bearer ${auth.user.token}`;

        if (!!requiredUserType && auth.user.userType != requiredUserType) {
            return <Navigate to="/accessDenied" state={{from: location}} replace />;
        }        
    }



    const checkLoginStatus = () => {        
        api.get("/events")
            .then((res) => {
                console.log(res);
            })
            .catch((error) => {            
                const { status } = error.response;
                if (status == "401") {
                    navigate("/login")
                }                
            })
    }

    // setInterval(checkLoginStatus, 5000);  

    return (        
        children
    );
}

export default RequireAuth;