import React from "react";
import { Col, Row } from "react-bootstrap";

const UnauthorizedLayout = () => {
    return (
        <Row>
            <Col><h1>Not Authorized</h1></Col>
        </Row>
    )
}

export default UnauthorizedLayout;