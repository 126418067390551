import React, { useEffect, useMemo, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import AgentDisplay from "../Common/AgentDisplay";
import api from "../Common/api";
import ReactTable from "../ReactTable/ReactTable";
import EditUserDialog from "./EditUserDialog";

const Users = () => {
    const { t } = useTranslation();
    const columns = useMemo(() => [
        {Header: t("user.username"), colWidth: "20%", accessor: 'username'},
        {Header: t("user.userType"), colWidth: "10%", accessor: 'userType'},
        {Header: t("user.agent"), colWidth: "40%", accessor: 'agent', Cell: ({row}) => (
            row.original.agent ? <AgentDisplay {...row.original.agent}/> : <strong>N/A</strong>
        )},
        {Header: t("user.active"), colWidth: "10%", accessor: 'active', Cell: ({row}) => (
            row.original.active ? <span className="text-success">正常</span> : <span className="text-danger">封鎖</span>
        )},
        {Header: t("operation"), colWidth: "15%", accessor: '', Cell: ({row}) => (
            <>
                <a href='' className="d-block d-sm-inline" onClick={(event) => {event.preventDefault(); setCursorData({...row.original}); setShowEditDialog(true);}}>[更改]</a>                
            </>
        )},        
    ], []);

    const [data, setData] = useState([]);   
    const [loading, setLoading] = useState(true); 

    const [showEditDialog, setShowEditDialog] = useState(false);
    const [cursorData, setCursorData] = useState({});

    const getData = () => {
        setLoading(true);
        api.get("/users")
            .then((res) => {
                const {success, result} = res.data;
                if (success) {
                    setData(result.map(d => {return {...d}}));
                }
            })
            .catch(() => {})
            .finally(() => {
                setLoading(false);
            })
    }

    useEffect(getData, []);

    return (
        <>
            <Row>
                <Col><h1>用戶管理</h1></Col>
            </Row>

            <Row>
                <Col>
                    <ReactTable {...{columns, data, loading}} />
                </Col>
            </Row>

            <EditUserDialog show={showEditDialog} setShow={setShowEditDialog} data={cursorData} />
        </>
    )
}

export default Users;