import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useAuth } from "../AuthContext/AuthContext";
import { setupInterceptors } from "./api";


const InjectAxiosInterceptors = () => {
    const navigate = useNavigate();
    const auth = useAuth();

    useEffect(() => {
        setupInterceptors(navigate, auth);

    }, []);

    const [intervalFunc, setIntervalFunc] = useState(null);
    useEffect(() => {
        if (!!intervalFunc) {
			console.log("Cleared the interval");
			clearInterval(intervalFunc);
			setIntervalFunc(null);
		}
        
        if (!auth.user) {
            return;
        }

		console.log("Setting up...")
		setIntervalFunc(setInterval(() => {
            const isTokenExpired = token => token && Date.now() >= (JSON.parse(atob(token.split('.')[1]))).exp * 1000;

            if (!!auth.user) {                
                const { token } = auth.user;
                if (!!token && isTokenExpired(token)) {

                    /** Try to use refresh token get the auth again */            
                    // auth.refreshAuth(() => {

                    // }, (resData) => {
                    //     navigate("/login?message=relogin", {replace: true});
                    //     console.log("Expired");
                    //     auth.clearAuth();                    
                    //     clearInterval(intervalFunc);
                    //     setIntervalFunc(null);
                    // });


                }
            }
			
		}, 60 * 1000));

    }, [auth.user]);

    return <></>;
}

export default InjectAxiosInterceptors;