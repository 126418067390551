import React, { Fragment, useEffect, useState } from 'react';
import { Alert, Button, Col, Container, Form, Modal, Row } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import ReactTable from '../ReactTable/ReactTable';
import { toast } from 'react-toastify';
import { useAuth } from '../AuthContext/AuthContext';
import api from '../Common/api';
import { useForm } from 'react-hook-form';
import FormField from '../Common/FormField';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

const Agents = (props) => {

    const { t } = useTranslation();

    const columns = React.useMemo(() => [
        {Header: t("agent.agentCode"), accessor: 'agentCode'},
        {Header: t("agent.name"), accessor: 'name'},
        {Header: t("agent.division"), accessor: 'division'},
        {Header: t("agent.agency"), accessor: 'agency'},
        {
            id: 'operation',
            Header: t("operation"),
            Cell: ({ row }) => (
                <div>                    
                </div>
            ),
            disableSortBy: true
        },
    ], []);    


    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [showNewAgentDialog, setShowNewAgentDialog] = useState(false);
    const [agentForm, setAgentForm] = useState({agentCode: "", name: "", division: "", agency: ""});
    const [agentFormError, setAgentFormError] = useState("");

    const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);


    const [showBatchAddModal, setShowBatchAddModal] = useState(false);
    const [batchAddLoading, setBatchAddLoading] = useState(false);

    const batchAddFormProps = useForm();
    const formProps = useForm();
    const { register, handleSubmit, setValue, getValues, reset, watch, formState: { errors } } = formProps;    

    const handleChange = (event) => {
        const target = event.target;
        const name = target.name;
        const newAgentForm = {...agentForm};
        newAgentForm[name] = target.value;
        setAgentForm(newAgentForm);
    }

    const getData = () => {
        setLoading(true);
        api.get("/agents")        
            .then((res) => {
                if (res.data.success) {
                    setData([...res.data.result]); 
                    setLoading(false);                 
                }
            })
    }


    const onDelete = () => {        
        const agentIds = selectedRows.map(agent => agent && agent.agentId);
        api.delete("/agents", {data: {agentIds: agentIds}})
            .then((res) => {
                const {success, result: deletedCount} = res.data;
                if (success) {
                    toast.success(`已刪除 ${deletedCount} 項記錄`);                    
                    getData();
                } else {
                    toast.error("系統出現錯誤");
                }

                setShowDeleteConfirmModal(false);
            })
    }    

    const onSubmit = (data) => {        
        api.post("/agents", data)
            .then((res) => {
                if (res.data.success) {
                    toast.success("人員新增成功", {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,                        
                    });
                    setShowNewAgentDialog(false);
                    getData();
                } else {
                    setAgentFormError(res.data.message);
                }
            })
    }

    const onBatchAddFormSubmit = (data) => {
        setBatchAddLoading(true);
        const entries = data.entries;
        const agentData = [];
        entries.split("\n").forEach(entry => {
            const splittedEntry = entry.split(",");
            const agent = {
                agentCode: splittedEntry[0].trim(),
                name: splittedEntry[1].trim(),
                division: splittedEntry[2].trim(),
                agency: splittedEntry[3].trim(),
            };            
            agentData.push(agent);
        })

        const formData = {
            agents: agentData
        }

        api.post("/agents/batchAdd", formData)
            .then((res) => {
                const { success, result } = res.data;
                if (success) {
                    setShowBatchAddModal(false);
                    getData();
                    toast.success(`新增了 ${result.noOfInserted} 項、更新了 ${result.noOfUpdated} 項、${result.noOfError} 項存在錯誤`);
                }
            })
            .finally(() => {
                setBatchAddLoading(false);
            });
    }

    useEffect(() => {
        getData();
    }, []);

    useEffect(() => {
        batchAddFormProps.reset();
    }, [showBatchAddModal])

    return (
        <Fragment>
            <Row>
                <Col>
                    <h1>人員管理</h1>
                </Col>
            </Row>

            <Row>
                <Col>
                    <Button variant='primary' onClick={() => { setShowNewAgentDialog(true) }}>新增</Button>
                    <Button variant='primary' onClick={() => setShowBatchAddModal(true)} className='ms-2'>批量新增</Button>
                </Col>
            </Row>

            <Row>
                <Col>
                    <ReactTable {...{columns, data, loading, setSelectedRows}} />
                </Col>
            </Row>

            <div className="mt-4" style={{"position": "sticky", "bottom": "0", "background": "#fff"}}>
                {selectedRows && selectedRows.length > 0 && (
                    <div className="p-2">
                        <Button variant='danger' type='button' onClick={() => setShowDeleteConfirmModal(true)}>刪除 { selectedRows.length } 項記錄</Button>
                    </div>
                )}                    
            </div>            

            <Modal show={showNewAgentDialog} onHide={() => setShowNewAgentDialog(false)} size="xl" backdrop="static" keyboard={false}>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Modal.Header closeButton>
                        <Modal.Title>新增人員</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {!!agentFormError ? (
                        <Alert variant="danger">
                            <Alert.Heading>錯誤</Alert.Heading>
                            <p>
                                {agentFormError}
                            </p>
                        </Alert>) : null}                    
                            <Form.Group className="mb-3" controlId="agentCode">                            
                                    <FormField controlId="agentCode" label={t("agent.agentCode")} type="text"  
                                        rules={{ required: true }} {...formProps}                                     
                                    />
                            </Form.Group>                        
                            <Form.Group className="mb-3" controlId="name">                            
                                    <FormField controlId="name" label={t("agent.name")} type="text"  
                                        rules={{ required: true }} {...formProps}                                     
                                    />
                            </Form.Group>                        
                            <Form.Group className="mb-3" controlId="division">                            
                                    <FormField controlId="division" label={t("agent.division")} type="text"  
                                        rules={{ required: true }} {...formProps}                                     
                                    />
                            </Form.Group>                        
                            <Form.Group className="mb-3" controlId="agency">                            
                                    <FormField controlId="agency" label={t("agent.agency")} type="text"  
                                        rules={{ required: true }} {...formProps}                                     
                                    />
                            </Form.Group>                                                                                                                                                                 
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant='secondary' onClick={() => setShowNewAgentDialog(false)}>取消</Button>
                        <Button variant='primary' type='submit'>儲存</Button>
                    </Modal.Footer>
                </Form>
            </Modal>

            <Modal show={showDeleteConfirmModal} onHide={() => {setShowDeleteConfirmModal(false)}}>
                <Modal.Header closeButton>
                    <Modal.Title>確定刪除？</Modal.Title>
                </Modal.Header>
                <Modal.Body>{`確定刪除 ${selectedRows.length} 項記錄？`}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => {setShowDeleteConfirmModal(false)}}>
                        取消
                    </Button>
                    <Button variant="primary" onClick={onDelete}>
                        確定
                    </Button>
                </Modal.Footer>
            </Modal>             

            <Modal show={showBatchAddModal} onHide={() => {setShowBatchAddModal(false)}} size="xl">
                <Form onSubmit={batchAddFormProps.handleSubmit(onBatchAddFormSubmit)}>
                    <Modal.Header closeButton>
                        <Modal.Title>批量新增</Modal.Title>
                    </Modal.Header>
                    
                    <Modal.Body>                
                        <FormField controlId="entries" label="批量資料" as="textarea" placeholder="例子：10037,HENRY CHENG,D0013,SFO - Henry Cheng" rows={15} {...batchAddFormProps} />                    
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" type="button" onClick={() => {setShowBatchAddModal(false)}} disabled={batchAddLoading}>
                            取消
                        </Button>
                        <Button variant="primary" type="submit" disabled={batchAddLoading}>{batchAddLoading ? <><FontAwesomeIcon icon={ faSpinner } spin/> 載入中</> : "儲存"}</Button>
                    </Modal.Footer>
                </Form>
            </Modal>   

        </Fragment>
    )

}

export default Agents;