import React, { Fragment, useEffect, useMemo, useState } from "react";
import { Card, Col, FloatingLabel, Form, InputGroup, Pagination, Row, Table } from "react-bootstrap";
import { usePagination, useRowSelect, useTable, useAsyncDebounce, useGlobalFilter, useSortBy, useFilters } from "react-table/dist/react-table.development";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleUp, faAngleDown, faTabletAndroid } from "@fortawesome/free-solid-svg-icons";
import { PropagateLoader } from "react-spinners";
import { useLocation, useNavigate } from "react-router";

const IndeterminateCheckbox = React.forwardRef(
    ({ indeterminate, ...rest }, ref) => {
        const defaultRef = React.useRef()
        const resolvedRef = ref || defaultRef

        React.useEffect(() => {
            resolvedRef.current.indeterminate = indeterminate
        }, [resolvedRef, indeterminate])

        return (
            <>
                <input type="checkbox" ref={resolvedRef} {...rest} />
            </>
        )
    }
)

const GlobalFilter = ({
    preGlobalFilteredRows,
    globalFilter,
    setGlobalFilter,
    gotoPage,
}) => {

    const count = preGlobalFilteredRows.length
    const [value, setValue] = React.useState(globalFilter)
    const onChange = useAsyncDebounce(value => {
      setGlobalFilter(value || undefined);
      gotoPage(0);
    }, 200)

    return (
        <>
        <FloatingLabel controlId="globalSearch" label="搜尋" className="mb-3">
            <Form.Control type="text"  value={value || ""} onChange={e => {
            setValue(e.target.value);
            onChange(e.target.value);
        }} placeholder={`${count} 項記錄...`} />
        </FloatingLabel>        
        </>
    );
}

const ReactTable = ({ id: tableId, columns, data, loading, setSelectedRows }) => {

    const location = useLocation();        
    const navigate = useNavigate();

    const storageItemKey = useMemo(() => {        
        return location.pathname + "_" + tableId;        
    }, [tableId, location, navigate]);

    let initState = {        
        pageSize: 25,        
    }

    const storedInitState = JSON.parse(window.sessionStorage.getItem(storageItemKey));

    const storedSortBy = React.useMemo(() => {
        if (!storedInitState || !storedInitState.sortBy) {
            return [];
        }

        return [...storedInitState.sortBy];
    
    }, [storedInitState]);
    const storedGlobalFilter = React.useMemo(() => {
        if (!storedInitState || !storedInitState.globalFilter) {
            return "";
        }        

        return storedInitState.globalFilter;
    }, [storedInitState]);


    if (!!storedInitState) {                
        initState = {...initState, ...{pageIndex: storedInitState.pageIndex, pageSize: storedInitState.pageSize}};                    
        initState.sortBy = storedSortBy;        
        initState.globalFilter = storedGlobalFilter;
    }



    const ELLIPSIS_PAGES = 5;
    const {
        getTableProps, getTableBodyProps, headerGroups, rows, page, prepareRow,

        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,

        selectedFlatRows,

        preGlobalFilteredRows,
        setGlobalFilter,

        toggleSortBy,        
        setSortBy,        

        state: { pageIndex, pageSize, selectedRowIds, globalFilter, sortBy },

    } = useTable({ columns, data, initialState: {...initState} }, useFilters, useGlobalFilter, useSortBy, usePagination, useRowSelect, hooks => {
        hooks.visibleColumns.push(columns => [
            // Let's make a column for selection
            {
                id: 'selection',
                // The header can use the table's getToggleAllRowsSelectedProps method
                // to render a checkbox
                Header: ({ getToggleAllPageRowsSelectedProps }) => (
                    <div>
                        <IndeterminateCheckbox {...getToggleAllPageRowsSelectedProps()} />
                    </div>
                ),
                colWidth: "5%",
                // The cell can use the individual row's getToggleRowSelectedProps method
                // to the render a checkbox
                Cell: ({ row }) => (
                    <div>
                        <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
                    </div>
                ),
                disableSortBy: true
            },
            ...columns,
        ])
    });

    useEffect(() => {        
        if (!!setSelectedRows) {
            setSelectedRows([...selectedFlatRows.map(d => d.original)]);
        }
        
    }, [selectedFlatRows]);

    const getWindowDimensions = () => {
        const { innerWidth: width, innerHeight: height } = window;
        return {width, height};
    }

    const [ windowDimensions, setWindowDimensions ] = useState(getWindowDimensions());
    const [ displayCard, setDisplayCard ] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setWindowDimensions(getWindowDimensions());
        }        

        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    useEffect(() => {
       
        const data = {pageSize, pageIndex, sortBy, globalFilter};
        window.sessionStorage.setItem(storageItemKey, JSON.stringify(data));

    }, [pageSize, pageIndex, sortBy, globalFilter]);

    useEffect(() => {
        if (windowDimensions.width <= 576) {
            setDisplayCard(true);
        } else {
            setDisplayCard(false);
        }
    }, [windowDimensions]);

    return (
        <Fragment>
            <Row className="mt-4">
                <Col sm={4}>
                    <div>                    
                    <Form.Select size="lg" onChange={(e) => setPageSize(Number(e.target.value))}>                        
                        {[10, 25, 50, 100].map(ps => {
                            return <option key={ps} value={ps} selected={ps == pageSize}>每頁顯示 { ps } 項記錄</option>
                        })}
                        
                    </Form.Select>
                    
                    </div>
                </Col>
                <Col sm={{ span: 4, offset: 4}}>
                    <GlobalFilter gotoPage={gotoPage} preGlobalFilteredRows={preGlobalFilteredRows} globalFilter={globalFilter} setGlobalFilter={setGlobalFilter} />                    
                </Col>
            </Row>
            <Row>
                <Col>
                    <div>
                        顯示第 { (pageIndex * pageSize) + 1  } 至 { Math.min((pageIndex * pageSize) + pageSize, rows.length)  } 項結果，共 { rows.length } 項

                    <div className="float-end">                      
                        <Pagination>
                            <Pagination.First onClick={() => gotoPage(0)} disabled={!canPreviousPage} />
                            <Pagination.Prev onClick={() => previousPage()} disabled={!canPreviousPage} />
                            {pageCount <= 10 ? (
                                Array.from(Array(pageCount)).map((page, index) => (
                                    <Pagination.Item key={index} onClick={() => gotoPage(index)} active={pageIndex === index}>{index + 1}</Pagination.Item>
                                ))
                            ) :
                                <>
                                    {pageIndex < ELLIPSIS_PAGES - 1 && (
                                        Array.from(Array(ELLIPSIS_PAGES)).map((page, index) => (
                                            <Pagination.Item key={index} onClick={() => gotoPage(index)} active={pageIndex === index}>{index + 1}</Pagination.Item>
                                        ))
                                    )
                                    }

                                    {pageIndex >= ELLIPSIS_PAGES - 1 && 
                                        <Pagination.Ellipsis />
                                    }

                                    {pageIndex >= ELLIPSIS_PAGES - 1 && pageIndex <= pageCount - ELLIPSIS_PAGES && (
                                        <>                                        
                                        {
                                            Array.from(Array(ELLIPSIS_PAGES)).map((page, index) => {
                                                const theIndex = pageIndex + (index - Math.floor((ELLIPSIS_PAGES / 2)));
                                                return <Pagination.Item key={index} onClick={() => gotoPage(theIndex)} active={pageIndex === theIndex}>{theIndex + 1}</Pagination.Item>
                                            })                                            
                                        }                                        
                                        </>
                                    )}

                                    {pageIndex <= pageCount - ELLIPSIS_PAGES &&
                                        <Pagination.Ellipsis />
                                    }

                                    {pageIndex > pageCount - ELLIPSIS_PAGES && pageIndex <= pageCount - 1 &&
                                        Array.from(Array(ELLIPSIS_PAGES)).map((page, index) => {
                                            const theIndex = pageCount - ELLIPSIS_PAGES + index;                                            
                                            return <Pagination.Item key={index} onClick={() => gotoPage(theIndex)} active={pageIndex === theIndex}>{theIndex + 1}</Pagination.Item>
                                        })
                                    }

                                </>
                            }

                            <Pagination.Next onClick={() => nextPage()} disabled={!canNextPage} />
                            <Pagination.Last onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage} />
                        </Pagination>
                    </div>
                    </div>
                </Col>
            </Row>            
            <Row>
                <Col>
                    {displayCard && (
                        <Form.Select size="lg" onChange={(e) => {                                                                                    
                            const option = e.target.options[e.target.options.selectedIndex];                                                        
                            const {id, order} = option.dataset;                            
                            if (!!id && !!order) {                                
                                toggleSortBy(id, order == "desc", false);                                                           
                            }
                        }}>                        
                            <option>預設排序</option>
                            {headerGroups.map(headerGroup => headerGroup.headers.filter(column => column.canSort).map((column) => (
                                <>
                                    <option data-id={column.id} data-order="asc" value={column.id + "_asc"}>按 { column.render("Header") } 順序</option>
                                    <option data-id={column.id} data-order="desc" value={column.id + "_desc"}>按 { column.render("Header") } 倒序</option>                                
                                </>
                            )))}

                        </Form.Select>
                    )}

                    <Table responsive hover {...getTableProps()} className="mt-4">
                        { displayCard ? page.map((row, i) => {
                        prepareRow(row);
                        return (
                            <>                                    
                                <Card className="my-4" onClick={() => row.toggleRowSelected()}>
                                    <Card.Body>
                                        {headerGroups.map(headerGroup => (
                                            headerGroup.headers.map((column, columnIdx) => (
                                                <>
                                                    <dt>                                                        
                                                        { column.id != "selection" && column.render("Header") }
                                                    </dt>
                                                    <dd>{ row.cells[columnIdx].render('Cell') }</dd>
                                                </>
                                            ))
                                        ))
                                        }
                                    </Card.Body>
                                </Card>   
                            </>
                        )}) :
                            (
                                <>
                                    <thead>
                                        {headerGroups.map(headerGroup => (
                                            <tr {...headerGroup.getHeaderGroupProps()}>
                                                {headerGroup.headers.map(column => {
                                                    const thProps = {...column.getHeaderProps(column.getSortByToggleProps())};
                                                    thProps.style = {...thProps.style, ...column.getHeaderProps({width: column.colWidth})}
                                                    return (
                                                        <th {...thProps}>                                                
                                                            {column.render('Header')}                                            
                                                            { column.canSort && column.isSorted && <span className="mx-2">{column.isSortedDesc ? <FontAwesomeIcon icon={faAngleDown} />: <FontAwesomeIcon icon={faAngleUp} />}</span> }
                                                            {/* <div>{column.canFilter && column.render('Filter')}</div> */}
                                                        </th>
                                                    )
                                                })}
                                            </tr>
                                        ))}
                                    </thead>
                                    <tbody {...getTableBodyProps()}>
                                        {loading && <td colSpan={10000}><div className="text-center mt-4"><PropagateLoader size={15} /><br/>Loading...</div></td>}

                                        {page.map((row, i) => {
                                            prepareRow(row)
                                            return (
                                                <tr key={i} {...row.getRowProps()}>
                                                    {row.cells.map(cell => {
                                                        return (
                                                            <td {...cell.getCellProps()}>                                                    
                                                                {cell.render('Cell')}
                                                            </td>
                                                        )
                                                    })}

                                                </tr>
                                            )
                                        })}
                                    </tbody>                                
                                </>
                            )
                        }                        

                    </Table>
                </Col>
            </Row>
        </Fragment>
    )

}

export default ReactTable;