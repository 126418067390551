import React, { useEffect } from 'react';
import QrReader from 'react-qr-scanner';

const QrScanner = (props) => {    

    useEffect(() => {
        
    }, []);

    return (
        <div>
            <QrReader style={{height: "100%", width: "100%"}} delay={props.delay} onScan={props.handleScan} onError={props.handleError} constraints={props.constraints}></QrReader>
        </div>
    )
}

export default QrScanner;