import React, { Fragment, useEffect, useState } from 'react';
import { Alert, Button, ButtonGroup, Col, Container, Form, Modal, Row, Table } from 'react-bootstrap';
import ReactTable from '../ReactTable/ReactTable';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import api from '../Common/api';
import { useNavigate, useParams } from 'react-router';
import AgentDisplay from '../Common/AgentDisplay';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';


const EventEnrolls = (props) => {    
    
    const columns = React.useMemo(() => [
        {Header: '代理', id: 'agent', accessor: (row) => {
            const { agent } = row;
            return agent.agentCode + " " + agent.name + " " + agent.division + " " + agent.agency;
        }
        , Cell: ({row}) => {
            const { agent } = row.original;
            return (
                <AgentDisplay {...agent} />
            )
        }
        , Filter: ({column: {filterValue, preFilteredRows, setFilter}}) => {
            const count = preFilteredRows.length;
            return (
                <input
                    value={filterValue || ''}
                    onChange={e => {
                        setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
                    }}
                    placeholder={`Search ${count} records...`}
                />
            )
        }, filter: (rows, id, filterValue) => {
            return rows.filter(row => {                
                const agent = row.original[id];
                const lowerFilterValue = filterValue.toLowerCase();
                const agentCodeIncludes = agent.agentCode.toLowerCase().includes(lowerFilterValue);
                const nameIncludes = agent.name.toLowerCase().includes(lowerFilterValue);
                return agentCodeIncludes || nameIncludes;
            })
        }},
        {Header: '操作', 
            Cell: ({ row }) => (
                <div>
                    
                </div>
            ),
            disableSortBy: true
        }
    ], []);

    const [event, setEvent] = useState(null);
    const [data, setData] = useState([]);   
    const [loading, setLoading] = useState(true);     
    const [showBatchAddModal, setShowBatchAddModal] = useState(false);
    const { id } = useParams();

    const navigate = useNavigate();

    const { register, handleSubmit, reset, watch, formState: { errors } } = useForm();    
    
    const getEvent = () => {
        api.get("/events/" + id)
            .then((res) => {
                const {success, result} = res.data;
                if (success) {
                    setEvent({...result});                    
                }
            })
    }

    const getEventEnrolls = () => {
        api.get("/events/enrolls/" + id)
            .then((res) => {
                const {success, result} = res.data;
                if (success) {
                    setData([...result]);
                    setLoading(false);
                }
            })
    }

    const onSubmit = (data) => {        
        const { agentCodes } = data;
        const agentCodesArr = agentCodes.split("\n").map((agentCode) => agentCode.trim()).filter(agentCode => !!agentCode);
        api.post("/events/enrolls/" + id + "/batchAdd", {eventId: event.eventId, agentCodes: agentCodesArr})
            .then((res) => {
                const { success, result } = res.data;
                if (success) {
                    getEventEnrolls();
                    setShowBatchAddModal(false);
                    toast.success(`新增了 ${result.noOfInserted} 項、更新了 ${result.noOfUpdated} 項、${result.noOfError} 項存在錯誤`);
                }
            })
    }

    useEffect(() => {
          getEvent();
          getEventEnrolls();
    }, []);    

    useEffect(() => {
        reset();
    }, [showBatchAddModal])
    

    return (
        <Fragment>
            <Row>
                <Col>
                    <h1><Button variant="secondary" onClick={() => navigate(-1)}><FontAwesomeIcon icon={faArrowLeft} fixedWidth /></Button> 報名管理 - {event && event.title}</h1>
                </Col>
            </Row>

            <Row>
                <Col>
                    <Button variant='primary' onClick={() => setShowBatchAddModal(true)}>批量新增</Button>
                </Col>
            </Row>

            <Row>
                <Col>
                    <ReactTable {...{columns, data, loading}}></ReactTable>
                </Col>
            </Row>

            <Modal
                show={showBatchAddModal}
                // onHide={handleClose}
                backdrop="static"
                keyboard={false}
                size="xl"
            >
                <Form onSubmit={handleSubmit(onSubmit)}>
                <Modal.Header closeButton>
                    <Modal.Title>批量新增</Modal.Title>
                </Modal.Header>
                <Modal.Body>                    
                        <Form.Group className="mb-3" controlId="agentCodes">
                            <Form.Label>Agent Code (一行一個)</Form.Label>
                            <Form.Control as="textarea" rows={3} {...register("agentCodes", {required: true})} isInvalid={!!errors.agentCodes} />
                            <Form.Control.Feedback type="invalid">
                                {errors.agentCodes && <p>{ errors.agentCodes.message }</p>}
                            </Form.Control.Feedback>                            
                        </Form.Group>
                                      
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" type="button" onClick={() => setShowBatchAddModal(false)}>
                        取消
                    </Button>
                    <Button variant="primary" type="submit">新增</Button>
                </Modal.Footer>
                </Form>  
            </Modal>

        </Fragment>
    )

}

export default EventEnrolls;