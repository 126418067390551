import AsyncSelect from "react-select/async";
import Select from "react-select";
import AgentDisplay from "../Common/AgentDisplay";
import api from "../Common/api";
import debounce from "lodash/debounce";
import React, { useEffect, useState } from "react";

const debounceFunc = debounce((callback) => {       
    api.get("/agents")
    .then(res => {
        const {success, result} = res.data;
        if (success) {
            callback(result.map(d => {return {...d}}));
        }        
    })   
}, 500, {"trailing": true, "leading": false});  

RegExp.escape = function (s) {
    return s.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
};

const EventSelect = ({isMulti, value, onChange}) => {

    const loadAgentOptions = (inputValue, callback) => {        
        debounceFunc(callback);
    }

    const [events, setEvents] = useState([]);
    const getEvents = () => {
        api.get("/events")
        .then(res => {
            const {success, result} = res.data;
            if (success) {
                setEvents(result.map(d => {return {...d}}));
            }        
        })
    }

    useEffect(() => {
        getEvents();
    }, [])

    return (
        <Select
            inputId="event"
            name="event"
            // loadOptions={loadAgentOptions}                        
            options={events}
            formatOptionLabel={(data) => (
                <>{data.eventId} { data.title }</>
            )}                        
            getOptionValue={(option) => option.eventId + ""}
            value={value}
            onChange={(e) => {onChange && onChange(e)}}
            isClearable
            filterOption={(option, inputValue) => {     
                const { data: event } = option;
                if (!!inputValue) {
                    const regex = new RegExp("" + RegExp.escape(inputValue.toUpperCase()), "i");     
                    console.log(regex);           
                    return regex.test(event.title.toUpperCase());
                } else {
                    return true;
                }
            }}
            isMulti={isMulti}
        />  
    )
}

export default EventSelect;