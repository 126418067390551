import React from "react";

const AgentDisplay = (agent) => {

    if (!agent) {
        return <div>N/A</div>
    }
    return (
        <div>
            <strong>{agent.agentCode}</strong> <br/>
            {agent.name} <br/>
            <span className="text-muted">{agent.division} - {agent.agency}</span> <br/>                    
        </div>        
    )
}

export default AgentDisplay;