import React, { Fragment, useEffect, useState } from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import ReactTable from '../ReactTable/ReactTable';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import api from '../Common/api';
import moment from 'moment';
import { MOMENT_DISP_DATE_TIME_FORMAT } from '../Common/config';
import { useNavigate, useParams } from 'react-router';
import FormField from '../Common/FormField';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import LoadingButton from '../Common/LoadingButton';
import { useTranslation } from 'react-i18next';
import AgentDisplay from '../Common/AgentDisplay';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

const EventAttendances = (props) => {

    const { t } = useTranslation();
    const navigate = useNavigate();

    const columns = React.useMemo(() => [
        {Header: '代理', id: 'agent', accessor: (row) => {
            const { agent } = row;

            return (agent && agent.agentCode + " " + agent.name + " " + agent.division + " " + agent.agency) || "";
        }
        , Cell: ({row}) => {
            const { agent } = row.original;
            return (
                <AgentDisplay {...agent} />
            )
        }
        , Filter: ({column: {filterValue, preFilteredRows, setFilter}}) => {
            const count = preFilteredRows.length;
            return (
                <input
                    value={filterValue || ''}
                    onChange={e => {
                        setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
                    }}
                    placeholder={`Search ${count} records...`}
                />
            )
        }, filter: (rows, id, filterValue) => {
            return rows.filter(row => {                
                const agent = row.original[id];
                const lowerFilterValue = filterValue.toLowerCase();
                const agentCodeIncludes = agent.agentCode.toLowerCase().includes(lowerFilterValue);
                const nameIncludes = agent.name.toLowerCase().includes(lowerFilterValue);
                return agentCodeIncludes || nameIncludes;
            })
        }},
        {Header: 'Check-in 方式', accessor: 'checkinMethod.description'},
        {Header: 'Check-in 時間', accessor: 'checkinTimestamp', Cell: ({row}) => {
            const { checkinTimestamp } = row.original;
            return (
                <>
                    {moment(checkinTimestamp).format(MOMENT_DISP_DATE_TIME_FORMAT)}
                </>
            )
        }},
        {Header: t("operation"), 
            Cell: ({ row }) => (
                <div>
                    
                </div>
            ),
            disableSortBy: true
        }
    ], []);

    const [event, setEvent] = useState(null);
    const [data, setData] = useState([]);   
    const [loading, setLoading] = useState(true);     
    const [batchAddLoading, setBatchAddLoading] = useState(false);
    const [checkinMethodOptions, setCheckinMethodOptions] = useState([]);
    const [showBatchAddModal, setShowBatchAddModal] = useState(false);
    const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);
    const { id } = useParams();

    const formProps = useForm();
    const { register, handleSubmit, reset, watch, setValue, formState: { errors } } = formProps;    
    
    const getEvent = () => {
        api.get("/events/" + id)
            .then((res) => {
                const {success, result} = res.data;
                if (success) {
                    setEvent({...result});                      
                }
            })
    }

    const getAttendances = () => {
        setLoading(true);
        api.get("/events/attendances/" + id)
            .then((res) => {
                const {success, result} = res.data;
                if (success) {
                    setData([...result]);
                    setLoading(false);
                }
            })
    }

    const onSubmit = (data) => {    
        setBatchAddLoading(true);
        const formData = {...data};        
        formData.agentCodes = formData.agentCodes.split("\n").map((agentCode) => agentCode.trim()).filter(agentCode => !!agentCode);
        formData.checkinTimestamp = moment(formData.checkinTimestamp).format();

        api.post("/events/attendances/" + id + "/batchAdd", formData)
            .then((res) => {
                const { success, result } = res.data;
                if (success) {
                    setShowBatchAddModal(false);
                    getAttendances();
                    toast.success(`新增了 ${result.noOfInserted} 項、更新了 ${result.noOfUpdated} 項、${result.noOfError} 項存在錯誤`);
                }
            })
            .finally(() => {
                setBatchAddLoading(false);
            });
    }

    const onDelete = () => {      
        setDeleting(true);  
        const attendanceIds = selectedRows.map(attendance => attendance.attendanceId);
        api.delete("/events/attendances/" + id, {data: {attendanceIds: attendanceIds}})
            .then((res) => {
                const {success, result: deletedCount} = res.data;
                if (success) {
                    toast.success(`已刪除 ${deletedCount} 項記錄`);
                    setShowDeleteConfirmModal(false);
                    getAttendances();
                }
            })
            .finally(() => setDeleting(false));
    }

    useEffect(() => {
          getEvent();
          getAttendances();

          api.get("/events/attendances/checkinMethod")
            .then((res) => {
                const {success, result} = res.data;
                if (success) {
                    setCheckinMethodOptions([...result]);
                }
            })
    }, []);    

    useEffect(() => {
        if (!showBatchAddModal)
            reset();
    }, [showBatchAddModal])
    

    return (
        <Fragment>            
            <Row>
                <Col>
                    <h1><Button variant="secondary" onClick={() => navigate(-1)}><FontAwesomeIcon icon={faArrowLeft} fixedWidth /></Button> 點到管理 - {event && event.title}</h1>
                </Col>
            </Row>

            <Row>
                <Col>
                    <Button variant='primary' onClick={() => { setValue("checkinTimestamp", moment(event.effectiveFrom).toDate()); setShowBatchAddModal(true);}}>批量點到</Button>
                </Col>
            </Row>

            <Row>
                <Col>
                    <ReactTable {...{columns, data, loading, setSelectedRows}}></ReactTable>
                </Col>
            </Row>

            <Modal
                show={showBatchAddModal}
                onHide={() => {setShowBatchAddModal(false)}}
                backdrop="static"
                keyboard={false}
                size="xl"
            >
                <Form onSubmit={handleSubmit(onSubmit)}>
                <Modal.Header closeButton>
                    <Modal.Title>批量新增</Modal.Title>
                </Modal.Header>
                <Modal.Body>      
                        <FormField controlId="agentCodes" label="Agent Code (一行一個)" as="textarea" rows={3} rules={{ required: true }} {...formProps} />
                        <FormField controlId="checkinTimestamp" label="Check-in 時間" type="datepicker"  rules={{ required: true }} {...formProps} {...{datePickerConfig: {showTimeSelect: true, timeFormat: "HH:mm", dateFormat: "dd MMM yyyy HH:mm"}}} />
                        <FormField controlId="checkinMethod" label="Check-in 方式" type="select" rules={{ required: true }} {...formProps} options={checkinMethodOptions} />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" type="button" onClick={() => setShowBatchAddModal(false)} disabled={batchAddLoading}>
                        取消
                    </Button>
                    <Button variant="primary" type="submit" disabled={batchAddLoading}>{batchAddLoading ? <><FontAwesomeIcon icon={ faSpinner } spin/> 載入中</> : "新增"}</Button>
                </Modal.Footer>
                </Form>  
            </Modal>
            <hr/>
            
            <Row>
                <Col>
                    {selectedRows && selectedRows.length > 0 && (
                        <div>
                            <Button variant='danger' type='button' onClick={() => setShowDeleteConfirmModal(true)}>刪除 { selectedRows.length } 項記錄</Button>
                        </div>
                    )}                    
                </Col>
            </Row>

            <Modal show={showDeleteConfirmModal} onHide={() => {setShowDeleteConfirmModal(false)}}>
                <Modal.Header closeButton>
                    <Modal.Title>確定刪除？</Modal.Title>
                </Modal.Header>
                <Modal.Body>{`確定刪除 ${selectedRows.length} 項記錄？`}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => {setShowDeleteConfirmModal(false)}}>
                        取消
                    </Button>  
                    <LoadingButton variant="danger" onClick={onDelete} loading={deleting}>{t("confirm_delete")}</LoadingButton>
                </Modal.Footer>
            </Modal>            
        </Fragment>
    )

}

export default EventAttendances;