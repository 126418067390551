import React, { Fragment, useState } from 'react';
import { Card, Col, Container, Form, Row, Alert, Image } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useAuth } from '../AuthContext/AuthContext';
import LoadingButton from '../Common/LoadingButton';
import logo from '../sfo_logo.jpeg';

const LoginLayout = (props) => {

    const auth = useAuth();
    const {t, i18n} = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();

    const messageCode = searchParams.get("message");
    const from = location.state && location.state.from && location.state.from.pathname || "/";

    const { register, handleSubmit, reset, watch, formState: { errors } } = useForm();
    const [loginError, setLoginError] = useState();
    const [loading, setLoading] = useState(false);

    const onSubmit = data => {        
        setLoading(true);
        auth.signin(data.username, data.password, () => {            
            toast.success(t("login_success"));
            navigate(from, {replace: true});
            setLoading(false);            
        }, (resData) => {
            const { message } = resData;
            setLoginError(message);
            setLoading(false);
        });
    }
    

    return (
        <Fragment>
            <main>
               <Container>
                    <Image src={logo} fluid />

                   {messageCode && (
                        <Row className="justify-content-center mt-5">
                            <Col lg={5}>
                                <Alert variant="danger">
                                    <Alert.Heading>系統錯誤</Alert.Heading>
                                    <p>
                                        {messageCode == "relogin" && "請重新登入系統"}                                        
                                    </p>
                                </Alert>
                            </Col>
                        </Row>
                   )}

                    {loginError && (
                        <Row className="justify-content-center mt-5">
                            <Col lg={5}>
                                <Alert variant="danger">
                                    <Alert.Heading>系統錯誤</Alert.Heading>
                                    <p>
                                        {t(loginError)}
                                    </p>
                                </Alert>
                            </Col>
                        </Row>                   

                    )}
                    <Row className="justify-content-center">
                        <Col lg={5}>
                            <Card className="shadow-lg rounded-lg mt-5">
                                <Card.Header>Login</Card.Header>
                                <Card.Body>
                                    <Form onSubmit={handleSubmit(onSubmit)}>
                                        <Form.Group controlId="username">
                                            <Form.Label>Username</Form.Label>
                                            <Form.Control type="text" {...register("username", {required: true})} isInvalid={errors.username && errors.username.type === "required"} autoFocus />
                                            <Form.Control.Feedback type="invalid">
                                                This field is required
                                            </Form.Control.Feedback>                                            
                                        </Form.Group>
                                        <Form.Group controlId="password">
                                            <Form.Label>Password</Form.Label>
                                            <Form.Control type="password" {...register("password", {required: true})} isInvalid={errors.password && errors.password.type === "required"} />
                                            <Form.Control.Feedback type="invalid">
                                                This field is required
                                            </Form.Control.Feedback>
                                        </Form.Group>    
                                        <div className="mt-4">       
                                            <Row>
                                                <Col xs={9} className="d-grid">
                                                    <LoadingButton type="submit" variant="primary" className="" loading={loading}>登入</LoadingButton>
                                                </Col>
                                                <Col xs={3} className="d-grid">
                                                    <LoadingButton type="button" variant="secondary" onClick={() => reset()}>清除</LoadingButton>                                                
                                                </Col>                                        
                                            </Row>                                     
                                        </div>
                                    </Form>

                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container> 
            </main>
        </Fragment>
    );

}

export default LoginLayout;