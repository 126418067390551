import AsyncSelect from "react-select/async";
import Select from "react-select";
import AgentDisplay from "../Common/AgentDisplay";
import api from "../Common/api";
import debounce from "lodash/debounce";
import React, { useEffect, useState } from "react";

const debounceFunc = debounce((callback) => {       
    api.get("/agents")
    .then(res => {
        const {success, result} = res.data;
        if (success) {
            callback(result.map(d => {return {...d}}));
        }        
    })   
}, 500, {"trailing": true, "leading": false});  

RegExp.escape = function (s) {
    return s.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
};

const AgentSelect = ({isMulti, value, onChange}) => {

    const loadAgentOptions = (inputValue, callback) => {        
        debounceFunc(callback);
    }

    const [agents, setAgents] = useState([]);
    const getAgents = () => {
        api.get("/agents")
        .then(res => {
            const {success, result} = res.data;
            if (success) {
                setAgents(result.map(d => {return {...d}}));
            }        
        })
    }

    useEffect(() => {
        getAgents();
    }, [])

    return (
        <Select
            inputId="agent"
            name="agent"
            // loadOptions={loadAgentOptions}                        
            options={agents}
            formatOptionLabel={(data) => (
                <AgentDisplay {...data} />
            )}                        
            getOptionValue={(option) => option.agentId + ""}
            value={value}
            onChange={(e) => {onChange && onChange(e)}}
            isClearable
            filterOption={(option, inputValue) => {     
                const { data: agent } = option;
                const regex = new RegExp("\\b" + RegExp.escape(inputValue.toUpperCase()), "i");                
                return regex.test(agent.agentCode.toUpperCase()) || regex.test(agent.name.toUpperCase());
            }}
            isMulti={isMulti}
        />  
    )
}

export default AgentSelect;