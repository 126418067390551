import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import BaseLayout from './BaseLayout/BaseLayout';
import LoginLayout from './LoginLayout/LoginLayout';
import Agents from './Agents/Agents';
import RequireAuth from './Common/RequireAuth';
import { AuthProvider } from './AuthContext/AuthContext';
import Events from './Events/Events';
import "react-datepicker/dist/react-datepicker.css";
import EventEnrolls from './Events/EventEnrolls';
import InjectAxiosInterceptors from './Common/InjectAxiosInterceptors';
import EventAttendances from './Events/EventAttendances';
import EventQrCode from './Events/EventQrCode';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationZH from './translation/zh/translation.json';
import Reports from './Reports/Reports';
import Users from './Users/Users';
import UnauthorizedLayout from './UnauthorizedLayout/UnauthorizedLayout';


i18n.use(initReactI18next)
	.init({
		resources: {
			zh: {
				translation: translationZH
			}
		},
		lng: "zh",
		fallbackLng: "zh",
		interpolation: {
			escapeValue: false
		}
	})


ReactDOM.render(
	<React.StrictMode>
		<AuthProvider>
			<BrowserRouter>
				<InjectAxiosInterceptors />				
				<Routes>
					<Route element={<RequireAuth requiredUserType="SUPERUSER"><BaseLayout/></RequireAuth>}>							
						<Route path="/agents" element={<RequireAuth><Agents /></RequireAuth>} />
						<Route path="/events" element={<Events />} />
						<Route path="/events/enrolls/:id" element={<EventEnrolls />} />
						<Route path="/events/attendances/:id" element={<EventAttendances />} />						
						<Route path="/reports" element={<Reports />} />						
						<Route path="/users" element={<Users />} />												
					</Route>					
					<Route element={<RequireAuth><BaseLayout/></RequireAuth>}>								
						<Route path="/" element={<App />}></Route>		
					</Route>
					<Route path="/events/qrcode/:id" element={<RequireAuth><EventQrCode /></RequireAuth>} />					
					<Route path="/login" element={<LoginLayout />}></Route>
					<Route path="/accessDenied" element={<BaseLayout><UnauthorizedLayout /></BaseLayout>}></Route>					
				</Routes>
			</BrowserRouter>
		</AuthProvider>
	</React.StrictMode>,
	document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
