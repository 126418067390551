import React from "react";
import { Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { JS_DATE_FORMAT } from "./config";

const DatePickerField = ({ controlId, register, setValue, getValues, rules, formState: { errors }, datePickerConfig, onChange }) => {
    return (
        <DatePicker 
            dateFormat={JS_DATE_FORMAT}
            selected={getValues(controlId)}
            onChange={(date) => {
                setValue(controlId, date, {shouldValidate: true});
                if (!!onChange) {
                    onChange(controlId, date);
                }
            }}
            customInput={<Form.Control type="text" placeholder="" name={controlId} {...register(controlId, rules)} isInvalid={!!errors && !!errors[controlId]} /> } 
            {...datePickerConfig} />
    );   
}

const DefaultField = ({ controlId, label, type, placeholder, as, rows, disabled, register, setValue, getValues, rules, formState: { errors }, options }) => {
    return (
        <Form.Control {...{as, type, rows, placeholder, disabled}} {...register(controlId, rules)} isInvalid={ !!errors[controlId]} />
    );
}

const FormField = (props) => {
    const { controlId, label, type, as, rows, register, setValue, getValues, rules, formState: { errors }, options, datePickerConfig } = props;

    let FieldElement = null;

    if (type == "select") {
        FieldElement = (
            <Form.Select {...register(controlId, rules)} isInvalid={!!errors[controlId]}>
                <option value=""></option>
                {!!options && options.map(option => {
                    return <option value={option.value} selected={getValues(controlId) == option.value}>{option.description}</option>
                })}
         </Form.Select>   
        )
    } else if (type == "datepicker") {
        FieldElement = <DatePickerField {...props} />
    } else {
        FieldElement = <DefaultField {...props} />         
    }

    return (
        <Form.Group className="mb-3" controlId={controlId}>
            <Form.Label>{ label }</Form.Label>
            { FieldElement }
            {!!errors && (
                <Form.Control.Feedback type="invalid">
                    {errors[controlId] && <p>{errors[controlId].message}</p>}
                </Form.Control.Feedback>
            )}                         
        </Form.Group>        
    )
}

export default FormField;