import React from 'react';
import logo from '../sfo_logo.jpeg';
import { Image } from 'react-bootstrap';
import { Link, NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPerson, faCalendar, faUsers, faColumns, faArrowRightFromBracket } from '@fortawesome/free-solid-svg-icons';
import { useAuth } from '../AuthContext/AuthContext';
import { useNavigate } from 'react-router';

const SideNav = () => {

    const auth = useAuth();

    const navigate = useNavigate();

    const signOut = () => {        
      auth.clearAuth(() => {
          navigate("/");
      });
  }

    return (
        <aside className="navbar navbar-vertical navbar-expand-lg navbar-dark">
        <div className="container-fluid">
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbar-menu">
            <span className="navbar-toggler-icon"></span>
          </button>
          <h1 className="navbar-brand navbar-brand-autodark">
            <Link to="/">
              <Image src={logo} width="110" height="32" alt="SFO" className="navbar-brand-image" />
            </Link>       
          </h1>
          <div className="navbar-nav flex-row d-lg-none">
            <div className="nav-item d-none d-md-flex me-3">
              <div className="btn-list">
                <a href="https://github.com/tabler/tabler" className="btn" target="_blank" rel="noreferrer">                
                  <svg xmlns="http://www.w3.org/2000/svg" className="icon text-github" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M9 19c-4.3 1.4 -4.3 -2.5 -6 -3m12 5v-3.5c0 -1 .1 -1.4 -.5 -2c2.8 -.3 5.5 -1.4 5.5 -6a4.6 4.6 0 0 0 -1.3 -3.2a4.2 4.2 0 0 0 -.1 -3.2s-1.1 -.3 -3.5 1.3a12.3 12.3 0 0 0 -6.2 0c-2.4 -1.6 -3.5 -1.3 -3.5 -1.3a4.2 4.2 0 0 0 -.1 3.2a4.6 4.6 0 0 0 -1.3 3.2c0 4.6 2.7 5.7 5.5 6c-.6 .6 -.6 1.2 -.5 2v3.5" /></svg>
                  Source code
                </a>
                <a href="https://github.com/sponsors/codecalm" className="btn" target="_blank" rel="noreferrer">                  
                  <svg xmlns="http://www.w3.org/2000/svg" className="icon text-pink" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M19.5 13.572l-7.5 7.428l-7.5 -7.428m0 0a5 5 0 1 1 7.5 -6.566a5 5 0 1 1 7.5 6.572" /></svg>
                  Sponsor
                </a>
              </div>
            </div>
            {/* <a href="?theme=dark" className="nav-link px-0 hide-theme-dark" title="Enable dark mode" data-bs-toggle="tooltip" data-bs-placement="bottom">              
              <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M12 3c.132 0 .263 0 .393 0a7.5 7.5 0 0 0 7.92 12.446a9 9 0 1 1 -8.313 -12.454z" /></svg>
            </a>
            <a href="?theme=light" className="nav-link px-0 hide-theme-light" title="Enable light mode" data-bs-toggle="tooltip" data-bs-placement="bottom">
              <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><circle cx="12" cy="12" r="4" /><path d="M3 12h1m8 -9v1m8 8h1m-9 8v1m-6.4 -15.4l.7 .7m12.1 -.7l-.7 .7m0 11.4l.7 .7m-12.1 -.7l-.7 .7" /></svg>
            </a> */}
            <div className="nav-item dropdown d-none d-md-flex me-3">
              <a href="#" className="nav-link px-0" data-bs-toggle="dropdown" tabIndex="-1" aria-label="Show notifications">
                <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M10 5a2 2 0 0 1 4 0a7 7 0 0 1 4 6v3a4 4 0 0 0 2 3h-16a4 4 0 0 0 2 -3v-3a7 7 0 0 1 4 -6" /><path d="M9 17v1a3 3 0 0 0 6 0v-1" /></svg>
                <span className="badge bg-red"></span>
              </a>
              <div className="dropdown-menu dropdown-menu-end dropdown-menu-card">
                <div className="card">
                  <div className="card-body">
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusamus ad amet consectetur exercitationem fugiat in ipsa ipsum, natus odio quidem quod repudiandae sapiente. Amet debitis et magni maxime necessitatibus ullam.
                  </div>
                </div>
              </div>
            </div>
            <div className="nav-item dropdown">
              <a href="#" className="nav-link d-flex lh-1 text-reset p-0" data-bs-toggle="dropdown" aria-label="Open user menu">
                {/* <span className="avatar avatar-sm" style="background-image: url(./static/avatars/000m.jpg)"></span> */}
                <div className="d-none d-xl-block ps-2">
                  <div>Paweł Kuna</div>
                  <div className="mt-1 small text-muted">UI Designer</div>
                </div>
              </a>
              <div className="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                <a href="#" className="dropdown-item">Set status</a>
                <a href="#" className="dropdown-item">Profile & account</a>
                <a href="#" className="dropdown-item">Feedback</a>
                <div className="dropdown-divider"></div>
                <a href="#" className="dropdown-item">Settings</a>
                <a href="#" className="dropdown-item">Logout</a>
              </div>
            </div>
          </div>
          <div className="collapse navbar-collapse" id="navbar-menu">
            <ul className="navbar-nav pt-lg-3">
              <li className="nav-item">
                <NavLink className="nav-link" to="/">
                    <span className="nav-link-icon d-md-none d-lg-inline-block">
                    <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><polyline points="5 12 3 12 12 3 21 12 19 12" /><path d="M5 12v7a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-7" /><path d="M9 21v-6a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v6" /></svg>
                    </span>
                    <span className="nav-link-title">
                        主頁
                    </span>                    
                </NavLink>                
              </li>                                
              {auth.user && auth.user.userType == "SUPERUSER" && (
                <>
                  <li className="nav-item">
                    <NavLink className="nav-link" to="/agents">
                      <span className="nav-link-icon d-md-none d-lg-inline-block">
                        <FontAwesomeIcon icon={faPerson} width="24" height="24" fixedWidth />
                      </span>
                      <span className="nav-link-title">
                        人員管理
                      </span>
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink className="nav-link" to="/events">
                      <span className="nav-link-icon d-md-none d-lg-inline-block">
                        <FontAwesomeIcon icon={faCalendar} width="24" height="24" fixedWidth />
                      </span>
                      <span className="nav-link-title">
                        活動管理
                      </span>
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink className="nav-link" to="/users">
                      <span className="nav-link-icon d-md-none d-lg-inline-block">
                        <FontAwesomeIcon icon={faUsers} width="24" height="24" fixedWidth />
                      </span>
                      <span className="nav-link-title">
                        用戶管理
                      </span>
                    </NavLink>
                  </li>

                  <li className="nav-item">
                    <NavLink className="nav-link" to="/reports">
                      <span className="nav-link-icon d-md-none d-lg-inline-block">
                        <FontAwesomeIcon icon={faColumns} width="24" height="24" fixedWidth />
                      </span>
                      <span className="nav-link-title">
                        報表
                      </span>
                    </NavLink>
                  </li>                                    
                </>
              )}

              <li className="nav-item">
                <a className="nav-link" href="#" onClick={signOut}>
                  <span className="nav-link-icon d-md-none d-lg-inline-block">
                  <FontAwesomeIcon icon={faArrowRightFromBracket} width="24" height="24" fixedWidth />
                  </span>
                  <span className="nav-link-title">
                    登出
                  </span>
                </a>
              </li>



            </ul>
          </div>
        </div>
      </aside>        
    )
}

export default SideNav;