import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const LoadingButton = (props) => {
    const { loading } = props;
    const { t } = useTranslation();

    return (
        <Button disabled={loading} {...props}>{ loading ? <><FontAwesomeIcon icon={faSpinner} spin/> {t("loading")}</> : props.children}</Button>
    )

}

export default LoadingButton;