import React from "react";
import { Row, Col, Form, Button, InputGroup, FormControl } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import FormField from "../Common/FormField";
import { useForm } from "react-hook-form";
import AgentSelect from "../Select/AgentSelect";
import { useEffect, useState } from "react";
import EventSelect from "../Select/EventSelect";
import api from "../Common/api";
import LoadingButton from "../Common/LoadingButton";
import moment from "moment";

const Reports = () => {
    const { t } = useTranslation(); 
    const formProps = useForm();    
    const { getValues, setValue, register, handleSubmit } = formProps;

    const [ formData, setFormData ] = useState(null);
    const [ loading, setLoading ] = useState(false);

    const [ effectiveToMinDate, setEffectiveToMinDate ] = useState(null);

    const onSubmit = (data) => {        
        setLoading(true);
        setFormData(data);
        data.eventId = data.event && data.event.eventId || "";
        data.agentIds = (data.agent && data.agent.map(a => a.agentId)) || [];

        data.effectiveFrom = !!data.effectiveFrom ? moment(data.effectiveFrom).startOf("day").format() : data.effectiveFrom;
        data.effectiveTo = !!data.effectiveTo ? moment(data.effectiveTo).endOf("day").format() : data.effectiveTo;

        api({
            url: "/reports",
            data: data,            
            method: "POST",
            responseType: "blob"
        }).then((res) => {
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement("a");
            link.href = url;
            const filename = res.headers["content-disposition"].split("filename=")[1];
            link.setAttribute("download", filename);
            document.body.appendChild(link);
            link.click();
        }).finally(() => {
            setLoading(false);
        })

    }

    useEffect(() => {
        register("agent");
        register("event");
    }, []);

    return (
        <>
            <Row>
                <Col>
                    <h1>{ t("reports.title") }</h1>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <Row className="mb-4">
                            <Col>
                                <Form.Group htmlFor="event"> 活動</Form.Group>
                                <EventSelect onChange={(e) => setValue("event", e)} />
                            </Col>
                        </Row>
                        <Row className="mb-4">
                            
                            <Col>                                
                                上班打卡
                                <Row>
                                    <Col>
                                        <FormField controlId="effectiveFrom" label={t("event.effectiveFrom")} type="datepicker"  rules={{  }} {...formProps} {...{datePickerConfig: {dateFormat: "dd MMM yyyy"}}} onChange={(controlId, date) => {setValue("effectiveTo", date);}}/>
                                    </Col>
                                    <Col>
                                        <FormField controlId="effectiveTo" label={t("event.effectiveTo")} type="datepicker"  rules={{  }} {...formProps} {...{datePickerConfig: {dateFormat: "dd MMM yyyy", minDate: getValues("effectiveFrom")}}} />
                                    </Col>                                
                                </Row>
                            </Col>                            
                        </Row>
                        <Row>
                            <Col sm="6">
                                <Form.Group>
                                    <Form.Label htmlFor="agent"> Agent</Form.Label>
                                    <AgentSelect onChange={(e) => setValue("agent", e)} isMulti />
                                </Form.Group>
                            </Col>
                            <Col sm={3}>
                                <FormField controlId="division" label={t("agent.division")} {...formProps} />
                            </Col>
                            <Col sm={3}>
                                <FormField controlId="agency" label={t("agent.agency")} {...formProps} />
                            </Col>                            
                        </Row>
                        {/* <Row>
                            <Col>
                                <Form.Group>
                                    <Form.Label>{t("event.effectiveFrom")}</Form.Label>
                                    <InputGroup.Text>由</InputGroup.Text>
                                    <FormControl />
                                    <InputGroup.Text>至</InputGroup.Text> 
                                    <FormControl />
                                </Form.Group>
                            </Col>
                        </Row> */}
                        <Row>
                            <Col>
                                <div>
                                    <div className="float-end">
                                        <LoadingButton variant="primary" type="submit" loading={loading}>搜尋</LoadingButton>                                        
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>            
            <hr/>
            {/* <Row>
                <Col>
                    <code>{ JSON.stringify(formData) }</code>
                </Col>
            </Row> */}
        </>
    )
}

export default Reports;