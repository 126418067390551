import React, { useEffect, useRef, useState } from "react";
import { Row, Col, Card, Button, Modal, ListGroup } from "react-bootstrap";
import { PropagateLoader } from "react-spinners";
import QrScanner from "../QrReader/QrScanner";
import { useNavigate, useParams } from "react-router";
import api from "../Common/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faXmark } from "@fortawesome/free-solid-svg-icons";
import { MOMENT_DISP_DATE_TIME_FORMAT } from "../Common/config";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { debounce } from "lodash";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import successSfx from '../success_sfx.mp3';
import errorSfx from '../error_sfx.mp3';
import { useSearchParams } from "react-router-dom";

const SCAN_COOLDOWN_TIME = 2 * 1000;

const successSfxPlayer = new Audio(successSfx);
const errorSfxPlayer = new Audio(errorSfx);

const debounceFunc = debounce((id, agentCode, thenFunc, finallyFunc) => {       
    api.post("/events/attendances/" + id, {agentCode: parseInt(agentCode, 10) + "", name: ""})
        .then(thenFunc)    
        .finally(finallyFunc);
}, 1000, {"trailing": false, "leading": true});  

const debounceTimeout = debounce((setScannedData, setScannedError, setReadingData) => {
    // setTimeout(() => {
        setScannedData(null);
        setScannedError(null);
        setReadingData(false);
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });

    // }, SCAN_COOLDOWN_TIME);
}, SCAN_COOLDOWN_TIME);

const EventQrCode = (props) => {

    const { id } = useParams();
    const navigate = useNavigate();
    const [event, setEvent] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [startScanning, setStartScanning] = useState(false);
    const [scanning, setScanning] = useState(false);
    const [scannedData, setScannedData] = useState(null);
    const [scannedError, setScannedError] = useState(null);
    const [devices, setDevices] = useState([]);
    const [camDeviceInfo, setCamDeviceInfo] = useState(null);
    const [readingData, setReadingData] = useState(false);

    const [searchParams, setSearchParams] = useSearchParams();

    const scanResultRowRef = useRef();    
    const scanScannerRowRef = useRef();

    const { t } = useTranslation();

    const scrollToResultRow = () => {
        const element = scanResultRowRef.current;
        if (!element)
            return;
        const { top } = element.getBoundingClientRect();
        // const { top: bodyTop } = document.body.getBoundingClientRect();        
        const scrollY = window.scrollY; 
        window.scrollTo({
            top: (top + scrollY + 20 /* Offset */),
            behavior: "smooth"
        });
    }

    const scrollToScannerRow = () => {
        const element = scanScannerRowRef.current;
        if (!element)
            return;
        const { top } = element.getBoundingClientRect();
        // const { top: bodyTop } = document.body.getBoundingClientRect(); 
        const scrollY = window.scrollY;               
        window.scrollTo({
            top: (top + scrollY + 20 /* Offset */),
            behavior: "smooth"
        });
    }    

    const getAgentCodeV1 = (text) => {
        const qrCodeDataObj = JSON.parse(text);
        return qrCodeDataObj.litmosUserId;
    }

    const getAgentCodeV2 = (text) => {
        const regex = /litmosUserId\x00\x00\x00(\d*)/gm;
        return (regex.exec(text))[1];       
    }

	const handleScan = data => {        
		if (!!data) {
            setScanning(true);
            setReadingData(true);
            setScannedData(null);
            setScannedError(null);
            // console.log(data.canvas.toDataURL("image/png"));
            try {
                let agentCode = "";
                try {
                    agentCode = getAgentCodeV1(data.text);
                } catch (err) {
                    agentCode = getAgentCodeV2(data.text);
                }            

                debounceFunc(event.eventId, agentCode, (res) => {
                    const {success, result, message} = res.data;                                       
    
                    if (success) {
                        const { attendance, messageCode, takeAttendance, late, lateMinutes } = result;                        
                        if (takeAttendance) {
                            setScannedData({...attendance, late, lateMinutes});	
                            successSfxPlayer.play();
                        } else {
                            if (messageCode === "AGENT_NOT_ENROLLED");
                            setScannedError({message: event.enforceMsg || t(messageCode)});
                            errorSfxPlayer.play();
                        }
                    } else {                        
                        if (message === "AGENT_NOT_FOUND") {
                            setScannedError({message: t(message)});
                            errorSfxPlayer.play();
                        }
                    }
                        
                }, () => {
                    setScanning(false);                    
                    // setReadingData(false);
                    scrollToResultRow();                    
                    debounceTimeout(setScannedData, setScannedError, setReadingData);
                });
    
            } catch (error) {
                setScannedError({message: "QR Code 格式錯誤"});
                errorSfxPlayer.play();
                setScanning(false);
                // setReadingData(false);
                scrollToResultRow();
                debounceTimeout(setScannedData, setScannedError, setReadingData);
            } 



		}
		
	}
	const handleError = error => {
		alert('Error' + error)
	}

    const getEvent = (eventId) => {
        setLoading(true);
        setError(false);
        api.get("/events/" + eventId)
            .then((res) => {
                const {success, result: event} = res.data;
                if (success) {
                    setEvent({...event});                    
                } else {
                    setError(true);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }

    const getDevices = () => {
        const devices = [];
        navigator.mediaDevices.getUserMedia({video: true})
            .then(s => {                

                navigator.mediaDevices.enumerateDevices()
                .then(deviceInfos => {
                    deviceInfos.forEach((deviceInfo, index) => {                        
                        if (deviceInfo.kind === "videoinput") {
                            const {label, deviceId} = deviceInfo;
                            devices.push({label: label || "Camera " + (index + 1), deviceId});
                        }
                    })
    
                    setDevices(devices);
                    if (devices.length > 0)
                        setCamDeviceInfo({...devices[1]});
                    
                })

                s.getTracks().forEach(track => track.stop());

            });



    }

    const [showSelectCamDialog, setShowSelectCamDialog] = useState(false);
    const onStartScanBtnClick = () => {                
        setShowSelectCamDialog(true);
    }

    const startScan = () => {         
        scrollToScannerRow();
        setStartScanning(true);     
        setShowSelectCamDialog(false);        
    }

    useEffect(() => {
        getEvent(id);
        getDevices();

    }, []);

    useEffect(() => {
        const kioskMode = searchParams.get("kioskMode");        
        if (!!kioskMode) {
            /** Frequently check if the event required update */            
            const checkTodayWorkingEvent = () => {
                if (!event)
                    return;                
                console.log("Checking today working event");
                api.get("/events/todayWorkingEvent", {params: {}})
                .then(res => {
                    const {success, result} = res.data;
                    if (success) {
                        if (result.eventId != event.eventId) {
                            setEvent({...result});
                        }                        
                    }
                })                
            }            
            clearInterval(window.kioskInterval);
            window.kioskInterval = setInterval(checkTodayWorkingEvent, 60 * 1000 /* 60 seconds */);
        }
    }, [event])

    let cardContent;

    if (loading) {
        cardContent = (<div className="text-center m-4"><PropagateLoader size={15} /><br/>載入中...</div>);
    } else if (error) {

    } else {
        cardContent = (
            <>
                <h1>{ event.title }</h1>                    
                    { startScanning ?                                             
                        <div className="justify-content-center d-grid gap-2 mt-4">
                            { readingData ? 
                                (<div className="text-center m-4"><PropagateLoader size={15} /><br/>讀取中...</div>) : 
                                <QrScanner
                                    delay={30000}
                                    handleScan={handleScan}
                                    handleError={handleError}                                    
                                    constraints={{video: {deviceId: camDeviceInfo.deviceId}}}
                                />
                            }

                        </div>
                    : 
                        <div className="d-grid gap-2 mt-4">
                            <Button variant="primary" size="lg" onClick={onStartScanBtnClick}>打開鏡頭</Button>
                        </div>
                    }                    
                
                
            </>
        );
    }

    return (
        <>    
            <Row className="justify-content-center mt-5" ref={scanScannerRowRef}>
                <Col lg={8}>
                    <Button variant="secondary" onClick={() => navigate(-1)}><FontAwesomeIcon icon={faArrowLeft} fixedWidth /></Button>
                </Col>
            </Row>        
            <Row className="justify-content-center">
                <Col lg={8}>
                    <Card className="shadow-lg rounded-lg mt-5">
                        <Card.Header>活動點到</Card.Header>
                        <Card.Body>
                            { cardContent }                          
                        </Card.Body>
                    </Card>
                </Col>
            </Row>        

            { (!!scannedData || !!scannedError || scanning) && (
            <Row className="justify-content-center" ref={scanResultRowRef}>
                <Col lg={8}>
                    <Card className="shadow-lg rounded-lg mt-5">                        
                        <Card.Body>                            
                            <div className="justify-content-center d-grid gap-2">
                                { scanning && <div className="text-center m-4"><PropagateLoader size={15} /><br/>載入中...</div> }
                                    
                                { !!scannedData && (
                                    <>
                                        <span className="text-center"><FontAwesomeIcon icon={faCheck} size="10x" className="text-success" /></span>
                                        <span className="text-center">已成功點到</span>
                                        {scannedData.late && (<span className="text-center text-danger">遲到 ({Math.abs(scannedData.lateMinutes)} 分鐘)</span>)}
                                        <hr />
                                        {scannedData.agent && (
                                            <>
                                                <p className="fs-1 text-center">{scannedData.agent.name}</p>
                                                <p className="fs-3 text-center">{scannedData.agent.agentCode}</p>
                                                <p className="fs-3 text-center text-muted">{scannedData.agent.division} - {scannedData.agent.agency}</p>
                                                <p className="fs-6 text-center text-muted">{moment(scannedData.agent.checkinTimestamp).format(MOMENT_DISP_DATE_TIME_FORMAT)}</p>
                                            </>
                                        )}

                                    </>
                                )}

                                { !!scannedError && (
                                    <>
                                       <span className="text-center"><FontAwesomeIcon icon={faXmark} size="10x" className="text-danger" /></span>
                                       <span className="text-center">{ scannedError.message || "系統錯誤，請再試一次" }</span>                                    
                                    </>
                                )}

                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>     
                      
            )}    


            <Modal
                show={showSelectCamDialog}
                onHide={() => setShowSelectCamDialog(false)}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>選擇鏡頭</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {devices && devices.length > 0 && camDeviceInfo && (
                        <ListGroup defaultActiveKey={"#" + camDeviceInfo.deviceId}>
                            { devices.map(device => {
                                return (
                                    <ListGroup.Item key={device.deviceId} action href={"#" + device.deviceId} onClick={() => {console.log({...device}); setCamDeviceInfo({...device})}}>
                                        {device.label}
                                    </ListGroup.Item>
                                )
                            }) }                                    
                        </ListGroup>
                    )}

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowSelectCamDialog(false)}>
                        取消
                    </Button>
                    <Button variant="primary" onClick={startScan}>選擇</Button>
                </Modal.Footer>
            </Modal>                 

            <div style={{"minHeight": "100vh"}}>


            </div>
        </>
    )
}

export default EventQrCode;