import React, { useEffect, useMemo, useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import FormField from "../Common/FormField";
import { debounce } from "lodash";
import api from "../Common/api";
import AsyncSelect from "react-select/async";
import AgentDisplay from "../Common/AgentDisplay";
import { setDefaultNamespace } from "i18next";
import LoadingButton from "../Common/LoadingButton";
import AgentSelect from "../Select/AgentSelect";
import { toast } from "react-toastify";


const debounceFunc = debounce((callback) => {   
    console.log("debounce");         
    api.get("/agents")
    .then(res => {
        const {success, result} = res.data;
        if (success) {
            callback(result.map(d => {return {...d}}));
        }
        
    })   
}, 500, {"trailing": true, "leading": false});  

const EditUserDialog = ({show, setShow, data}) => {
    const { t } = useTranslation();
    const handleClose = () => setShow(false);

    const formProps = useForm();
    const { getValues, setValue, handleSubmit, register, reset, formState: { errors } } = formProps;

    const [saving, setSaving] = useState(false);

    const activeOptions = useMemo(() => {
        return [{value: "true", description: "正常"}, {value: "false", description: "封鎖"}];
    }, []);

    useEffect(() => {
        Object.keys(data).forEach((key) => {
            setValue(key, data[key], {shouldValidate: true});
        });
    }, [data]);

    useEffect(() => {
        if (!show) {
            reset();
            setSaving(false);
        }        
    }, [show]);

    const loadAgentOptions = (inputValue, callback) => {
        debounceFunc(callback);
    }

    const onSubmit = (data) => {
        setSaving(true);
        console.log(JSON.stringify(data));
        api.put("/users/" + data.userId + "/", data).then((res) => {
            const { success, result } = res.data;
            if (success) {
                toast.success(t("success"));
                handleClose();               
            }            
        })
        
    }

    return (
        <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            size="xl"
        >
            <Form onSubmit={handleSubmit(onSubmit)}>
            <Modal.Header closeButton>
                <Modal.Title>{data.userId ? "更改" : "新增"}用戶</Modal.Title>
            </Modal.Header>
            <Modal.Body>       

   

                <FormField controlId="username" label={t("user.username")} type="text" disabled={!!getValues("username") ? "disabled" : ""} {...formProps} />
                <FormField controlId="password" label={t("user.password")} type="password" {...formProps} />
                <FormField controlId="confirmPassword" label={t("user.confirmPassword")} type="password" {...formProps} rules={{validate: {"samePassword": (val) => getValues().password == val || "Please provide same password"} } } />
                {/* <Form.Group className="mb-2">
                    <Form.Label htmlFor="agent">{t("user.agent")}</Form.Label>
                    <AgentSelect value={getValues("agent")} onChange={(e) => {setValue("agent", {...e}); setTest(!test)}}/>
                </Form.Group> */}

                <FormField controlId="active" label={t("user.active")} type="select" options={activeOptions} {...formProps} />

            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    取消
                </Button>                
                <LoadingButton variant="primary" type="submit" loading={saving}>更新</LoadingButton>
            </Modal.Footer>
            </Form>
        </Modal>
    )

}

export default EditUserDialog;